import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from "react-helmet";

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';
import PublicPage_Button_Purchase from '@betwixt/common/dist/components/PublicPage_Button_Purchase'

import { GET_MODULE } from '@betwixt/common/src/graphql/modules'
import { getModulePath } from '@betwixt/common/src/utils/modules'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import '@betwixt/common/src/scss/publicPage.scss'
import '../client/scss/publicPage.scss'

import pp_local_with_tablet from '../client/assets/images/pp_local_with_tablet.jpg';
import pp_menu_list from '../client/assets/images/pp_menu_list.png';
import pp_plates from '../client/assets/images/pp_plates.png';
import pp_plate_detail from '../client/assets/images/pp_plate_detail.png';
import pp_allergens from '../client/assets/images/pp_allergens.png';
import pp_prices_on_menus from '../client/assets/images/pp_prices_on_menus.png';
import pp_types from '../client/assets/images/pp_types.png';
import pp_switch from '../client/assets/images/pp_switch.svg';

const PublicPage = props => {
    
    const { Module, t, _t, i18n } = props;
    
    const API_filename = global.gConfig.config_id === 'production' ? '' : `--${global.gConfig.config_id}`
    const [price, setPrice] = useState(undefined)

    useEffect(() => {
        if (Module) {
            const plans = Module.subscription.plans;
            const primaryPlan = plans.find( plan => plan.hasOwnProperty('metadata') && plan.metadata.primary == "true" )
            if (primaryPlan)
                setPrice(primaryPlan)
        }
    }, [Module])
    
    return (
        <main id="PublicPage" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('PublicPage.page.title') }</title>
                <meta name="description" content={ _t('PublicPage.page.description') } />
                <link rel="canonical" href={ getModulePath({ moduleId: Module ? Module.moduleId : false, module: Module, lang: i18n.language }) } />
            </Helmet>

            <Header logoClass="twixtWhite" />

            <div className="container">
                <div className="productCard productCard_MM_Shop" style={{backgroundImage : `url(${pp_local_with_tablet})`}}>
                    <header>
                        <div>
                            <h1>
                                <span className="menu" dangerouslySetInnerHTML={{ __html: _t('PublicPage.header.menu') }} />
                                <span className="management" dangerouslySetInnerHTML={{ __html: _t('PublicPage.header.management') }} />
                            </h1>
                            <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.header.description') }} />
                        </div>
                        <PublicPage_Button_Purchase viewType="minimal" Module={Module} t={t} />
                    </header>

                    <div className="ipad-display"></div>
                
                    <div className="section-description">
                        <div className="title" dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.descriptions.title') }}></div>
                        <div className="extends1" dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.descriptions.extends1') }}></div>
                        <div className="extends2" dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.descriptions.extends2') }}></div>
                    </div>

                    <div className="section-menus">
                        <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.menus.title') }} />
                        <img src={pp_menu_list} alt={ _t('PublicPage.details.section.menus.alt') } />
                    </div>
                    
                    <div className="section-plates">
                        <img src={pp_plates} alt={ _t('PublicPage.details.section.plates.alt') } />
                        <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.plates.title') }} />
                    </div>

                    <div className="other">
                        <div className="section-ingredients">
                            <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.ingredients.title') }} />
                            <img src={pp_plate_detail} alt={ _t('PublicPage.details.section.ingredients.alt') } />
                        </div>
                        <ul className="section-other">
                            <li>
                                <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.other.title_1') }}></h2>
                            </li>
                            <li>
                                <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.other.title_2') }} />
                            </li>
                        </ul>
                    </div>

                    <div className="section-configuration">

                        <div className="title">
                            <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.title') }} />
                        </div>

                        <ul className="boxes">

                            <li className="allergens-vegetarian-vegan">
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.avv.title') }} />
                                    <p dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.avv.p') }} />
                                </div>
                                <img src={pp_allergens} alt={ _t('PublicPage.details.section.configuration.avv.alt') } />
                            </li>

                            <li className="prices">
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.prices.title') }} />
                                    <ul>
                                        <li dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.prices.ul.li_1') }} />
                                        <li dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.prices.ul.li_2') }} />
                                    </ul>
                                </div>
                                <img src={pp_prices_on_menus} alt={ _t('PublicPage.details.section.configuration.prices.alt') } />
                            </li>

                            <li className="types-menu-plates-ingredients">
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.types.title') }} />
                                </div>
                                <img src={pp_types} alt={ _t('PublicPage.details.section.configuration.types.alt') } />
                                <div className="switch">
                                    <img src={pp_switch} alt={ _t('PublicPage.details.section.configuration.types.switch.alt') } />
                                    <p dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.configuration.types.switch.p') }} />
                                </div>
                            </li>

                        </ul>

                    </div>

                    <div className="section-widget">
                        
                        <h2 dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.widget.title') }} />

                        <p dangerouslySetInnerHTML={{ __html: _t('PublicPage.details.section.widget.description') }} />

                        <a href={ getModulePath({ moduleId: 'widget', module: Module, lang: i18n.language }) }>
                            { _t('PublicPage.details.section.widget.goto') }
                            <FontAwesomeIcon icon={faChevronRight} />
                        </a>

                    </div>

                    <PublicPage_Button_Purchase viewType="full" Module={Module} t={t} price_info_extra={ _t('PublicPage.details.section.price') } />
                    
                </div>

            </div>
            
            <Footer Module={Module} />

        </main>
    )
}
export default compose(
    graphql(GET_MODULE, {
        options: {
            variables: { moduleId: 'menu-management' }
        },
        props: ({ data: { Module } }) => ({ Module })
    })
)(PublicPage)