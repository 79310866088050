import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from "react-helmet";

import { graphql, withApollo } from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';

import { GET_MODULE } from '@betwixt/common/src/graphql/modules'
import { getModulePath } from '@betwixt/common/src/utils/modules'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import '@betwixt/common/src/scss/publicPage.scss'
import '../client/scss/landingWidget.scss'

const PublicPage = props => {

    const { Module, t, _t, i18n } = props;
    const API_filename = global.gConfig.config_id === 'production' ? '' : `--${global.gConfig.config_id}`

    useEffect(() => {
        const script = document.createElement('script');
              script.src = `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${API_filename}.js`;
              script.async = true;
        document.body.appendChild(script);

        const stylesheet = document.createElement('link');
              stylesheet.rel = 'stylesheet';
              stylesheet.href = `${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${API_filename}.css`;
        document.head.appendChild(stylesheet);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <main id="LandingWidget" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('PublicPage.page.title') }</title>
                <meta name="description" content={ _t('PublicPage.page.description') } />
                <link rel="canonical" href={ getModulePath({ moduleId: 'widget', module: Module, lang: i18n.language }) } />
            </Helmet>

            <Header logoClass="twixtWhite" />

            <div className="container">
                <div className="">
                    <header>
                        <h1 dangerouslySetInnerHTML={{ __html: _t('LandingWidget.section.title') }} />
                        <a className="back" href={ getModulePath({ moduleId: 'menu-management', module: Module, lang: i18n.language }) }>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            { _t('LandingWidget.section.back') }
                        </a>
                        <h2 dangerouslySetInnerHTML={{ __html: _t('LandingWidget.section.description') }} />
                    </header>
                </div>
                <div className="productCard productCard_MM_WIDGET">
                    <div className="browser-header">
                        <div className="cta"> <span/><span/><span/> </div>
                        <div className="url">www.tuositoweb.it</div>
                    </div>
                    <div className="browser-content">
                        <div className="sx">
                            <div className="site-text" style={{ width: '50%' }} />
                            <div className="site-text" style={{ width: '100%' }} />
                            <div className="site-text" style={{ width: '75%' }} />
                            <br />
                            <br />
                            <div className="site-text" style={{ width: '40%' }} />
                            <div className="site-text" style={{ width: '55%' }} />
                            <div className="site-text" style={{ width: '90%' }} />
                        </div>
                        <div className="dx">
                            <div className="betwixt_mm_widget" data-clientid={global.gConfig.demo.client_id} />
                        </div>
                    </div>
                </div>
                <ul className="info">
                    <li>
                        <h4 dangerouslySetInnerHTML={{ __html: _t('LandingWidget.section.info.head') }} />
                        <code> 
                            {(`<link rel="stylesheet" href="${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${API_filename}.css" />`).toString()}
                        </code>
                    </li>
                    <li>
                        <h4 dangerouslySetInnerHTML={{ __html: _t('LandingWidget.section.info.body') }} />
                        <code>
                            {(`<script src="${global.gConfig.protocol}://${global.gConfig.sections.web.hostname}/cdn/mm_widget${API_filename}.js" async="true"></script>`).toString()}
                        </code>
                    </li>
                    <li>
                        <h4 dangerouslySetInnerHTML={{ __html: _t('LandingWidget.section.info.div') }} />
                        <code>
                            {('<div class="betwixt_mm_widget" data-clientid="il tuo clientId" />').toString()}
                        </code>
                    </li>
                </ul>
            </div>

            <Footer Module={Module} />

        </main>
    )
}

export default compose(
    graphql(GET_MODULE, {
        options: {
            variables: { moduleId: 'menu-management' }
        },
        props: ({ data: { Module } }) => ({ Module })
    })
)(PublicPage)