import React, { useState, useEffect, Fragment } from 'react'
import { Helmet } from "react-helmet";

import { graphql } from '@apollo/client/react/hoc';
import {flowRight as compose} from 'lodash'

import Header from '@betwixt/web/src/components/Header';
import Footer from '@betwixt/web/src/components/Footer';

import { GET_MODULE } from '@betwixt/common/src/graphql/modules'
import { getModulePath } from '@betwixt/common/src/utils/modules'
import PublicPage_Button_Purchase from '@betwixt/common/dist/components/PublicPage_Button_Purchase'

import '@betwixt/common/src/scss/publicPage.scss'
import '../client/scss/publicPage.scss'

const PublicPage = props => {

    const { Module, t, _t, i18n } = props;
    console.log( Module)
    return (
        <main id="PublicPage" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('PublicPage.page.title') }</title>
                <meta name="description" content={ _t('PublicPage.page.description') } />
                <link rel="canonical" href={ getModulePath({ moduleId: Module ? Module.moduleId : false, module: Module, lang: i18n.language }) } />
            </Helmet>

            <Header logoClass="twixtWhite" />

            <div className="container">
                <div className="productCard productCard_DEMO_">
                    <header>
                        <h1>PublicPage</h1>
                    </header>
                    <PublicPage_Button_Purchase viewType="minimal" Module={Module} t={t} />
                </div>
            </div>

            <Footer Module={Module} />

        </main>
    )
}

export default compose(
    graphql(GET_MODULE, {
        options: {
            variables: { moduleId: '_module_demo_' }
        },
        props: ({ data: { Module } }) => ({ Module })
    })
)(PublicPage)