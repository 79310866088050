import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import { withRouter, Redirect } from "react-router-dom"
import { withApollo } from '@apollo/client/react/hoc';
import { GET_PUBLIC_USERINFO } from '@betwixt/common/src/graphql/user'

import Footer from '@betwixt/web/src/components/Footer';
import NoMatch from '@betwixt/web/src/views/NoMatch'

import { getFileMedia, checkMediaIfExist } from '@betwixt/common/src/utils/media'
import Loading from '@betwixt/common/dist/components/Loading'

import UserPublicPage_scripts from '../components/UserPublicPage_scripts'
import '../client/scss/userPublicPage.scss'

const UserPublicPage = props => { 

    const { client, match, t, _t, i18n } = props;

    const companyname = match.params.companyname
    const viewId = match.params.viewId

    const [User, setUser] = useState(undefined)
    const [fileBrand, setFileBrand] = useState(false)

    useEffect( () => {
        async function getPublicUserInfo() {
            const userInfo = await client.query({ 
                query: GET_PUBLIC_USERINFO,
                variables: {
                    companyname: match.params.companyname
                }
            })
            if (userInfo.data.getPublicUserInfo.user)
                setUser(userInfo.data.getPublicUserInfo.user)
            else
                setUser(false)
        }
        getPublicUserInfo()
    }, [ client, companyname])
    
    useEffect(() => {
        const getFileBrand = async () => {
            if (User) {
                const checkImage = await checkMediaIfExist('image_brand', User.clientId);
                if (checkImage) {
                    const file = await getFileMedia('image_brand', User.clientId)
                    setFileBrand(file)
                }
            }
        }
        getFileBrand()
        
        const bodyRoot = document.getElementsByTagName('body');
        bodyRoot[0].style.backgroundColor = 'white'

    }, [User])
    
    if (User === undefined)
        return ( <Loading /> )

    if (!companyname && !viewId || User === false) {
        return <NoMatch />
    }

    return (
        <main id="UserPublicPage" role="main">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{ _t('UserPublicPage.page.title') }</title>
                <meta name="description" content={ _t('UserPublicPage.page.description') } />
                <link rel="canonical" href={ _t('UserPublicPage.page.canonical') } />
            </Helmet>

            <div className="container">
                {
                    (fileBrand) && (
                        <img src={fileBrand} className="brandClient"/>
                    )
                }
                <div className="betwixt_mm_widget" data-clientid={User.clientId}></div>
            </div>

            <UserPublicPage_scripts />

            <Footer />

        </main>
    )

}

export default withRouter(withApollo(UserPublicPage))