import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import cAssistance from '../views/Assistance'
import cWelcome from '../views/Welcome'
import cModules from '../views/Modules'
import cPrivacyPolicy from '../views/PrivacyPolicy'
import cCookiePolicy from '../views/CookiePolicy'
import cPublic from '../views/Public'
import cNoMatch from '../views/NoMatch'

import { graphql } from '@apollo/client/react/hoc';
import { GET_MODULES } from '@betwixt/common/src/graphql/modules'
import { lang_allowed_regex, lang_it_regex, lang_en_regex } from '@betwixt/common/src/utils/i18n'

const ManageRoutes = props => { 

    const { Modules } = props

    const getDetectedLanguage = () => {
        return (props.i18n.language.indexOf('-')) ? (props.i18n.language.split('-'))[0] : props.i18n.language;
    }

    const extendTranslations = (data) => {
        try {
            if(data.module.languages){
                data.module.languages.map( (language) => {
                    if( props.i18n.getResourceBundle(language, data.moduleId) === undefined ){
                        const resource = require(`@betwixt/common/dist/modules/${data.moduleId}/translation/${language}.json`)
                        props.i18n.addResourceBundle(language, data.moduleId, resource, true, true)
                    }
                    return false;
                })
            }
        } catch (err) {}
    }

    const ExtendsWithModulesRoutes = () => {

        if (Modules) {

            const detectLanguage = getDetectedLanguage()

            return Modules.map((data, index) => { 

                let MODULE = false

                try { 
                    MODULE = require(`@betwixt/common/dist/modules/${data.moduleId}/web/index`).default
                } catch (err) {}

                if (MODULE) {
                    
                    extendTranslations(data)

                    return MODULE.map((extPath) => {
                        const COMP = extPath.component
                        return (
                            <Route key={index} 
                                   path={extPath.path} 
                                   children={() => <COMP i18n={props.i18n} 
                                                         t={props.t} 
                                                         _t={(key, opts) => props.i18n.getResource(detectLanguage, data.moduleId, key, false, opts)} 
                                                         />} 
                                />
                        )
                    })
                }

            })
        }

    }

    return(
        <Switch>

            <Route exact path={`${lang_allowed_regex}/`} component={cWelcome} />

            { ExtendsWithModulesRoutes() }

            <Route path={`${lang_it_regex}/assistenza-servizi`} component={cAssistance} />
            <Route path={`${lang_en_regex}/assistance-services`} component={cAssistance} />

            <Route path={`${lang_it_regex}/servizi`} component={cModules} />
            <Route path={`${lang_it_regex}/servizio`} component={cModules} />
            <Route path={`${lang_en_regex}/services`} component={cModules} />
            <Route path={`${lang_en_regex}/service`} component={cModules} />

            <Route path={`${lang_allowed_regex}/privacy-policy`} component={cPrivacyPolicy} />

            <Route path={`${lang_allowed_regex}/cookie-policy`} component={cCookiePolicy} />

            <Route path={`${lang_allowed_regex}/public`} component={cPublic} />
            <Route path="service/menu-management/personal-page" component={cPublic} />
            
            <Route component={cNoMatch} />
        
        </Switch>
    )
}

export default 
    graphql(GET_MODULES, {
        props: ({ data: { Modules } }) => ({ Modules })
    })
(ManageRoutes)